.list-container {
  display: inline-block;
  max-height: 320px;
  max-width: 100%;
  overflow: auto;

  & {
  scrollbar-width: thin;
  scrollbar-color: #c4eaf7 transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  // &::-webkit-scrollbar-track {
  //   background: transparent;
  // }

  &::-webkit-scrollbar-thumb {
    background-color: #c4eaf7;
    border-radius: 8px;
  }

  table.channel-list {
    border-spacing: 0;
    font-size: 16px;

    border-collapse: collapse;

    tr.public-safety {
      color: red;
      background: #f9e5e2;

      td.channel-status {
        font-family: "sh-new-bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 600;
      }

      &.even {
        background: #f3dfdd;
      }
    }

    tr.usable {
      background: #fdfbe1;

      &.even {
        background: #f7f5dc;
      }
    }

    tr.vacant {
      background: #e3f5e8;

      &.even {
        background: #ddefe2;
      }
    }

    tr.busy.even {
      background: #f9f9f9;
    }

    td {
      padding: 15px;
    }

    .channel-number {
      font-family: "sh-new-bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: 600;
    }

    thead tr {
      color: #0089cc;

      th {
        padding: 5px;
        background: white;

        position: sticky;
        top: 0;

        position: -webkit-sticky;

        z-index: 2;
        box-shadow: inset 0 -1px 0 #0089cc;
      }
    }
  }
}
.legend {
  margin: 20px;
  text-align: left;
  line-height: 3em;
  font-size: 16px;

  @media (min-width: 1025px) {
    margin: 20px 0 0 0;
    max-width: 180px;
    float: left;
  }

  .square {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 10px;

    &.vacant {
      background: #e3f5e8;
    }
    &.usable {
      background: #fdfbe1;
    }
    &.public-safety {
      background: #f9e5e2;
    }
  }

  .footnote {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5em;
  }
}
