.header {
  border-bottom: 3px solid #0085bf;
  margin: 0 auto;
  padding: 10px 0;
  display: block;
  float: none;
  position: relative;
  width: 100%;
  font-size: 16px;
  min-height: 70px;
  clear: both;
  box-sizing: border-box;
  background-color: white;
  z-index: 100;

  * {
    outline: none;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
  }

  section {
    margin: 0;
    padding: 0;
    display: block;
    float: left;
    width: 100%;
  }

  .wrapper {
    height: 100%;
    padding: 0 30px;
    position: relative;
  }

  .logo {
    width: 220px;
    height: 50px;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    display: inline-block;

    a {
      margin: 0;
      float: left;
      width: 100%;
      color: #fff;
      font-size: 0.8125em;
      line-height: 50px;
      transition: background-color 200ms ease;

      display: inline-block;
      vertical-align: top;
      height: 100%;
      padding: 10px 0;
    }
  }

  .menu {
    float: right;
    margin: 0;
    padding: 0;
    display: block;
    max-height: 100%;
    overflow: visible;

    @media screen and (max-width: 800px) {
      display: none;
    }

    li {
      padding: 10px 15px;
      margin: 0;
      display: block;
      float: left;
      transition: background-color 200ms ease;
      font-size: 1em;

      a {
        margin: 0;
        display: inline-block;
        float: none;
        width: 100%;
        position: relative;
        font-family: "sh-new-bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
        color: #000;
        transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
        letter-spacing: -0.5px;
        line-height: 30px;
        font-size: 16px;
        border-bottom: none;
        font-weight: 600;
        padding: 0;
      }
    }
  }
}
