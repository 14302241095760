.error {
  position: fixed;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5em;
  border: solid 2px red;
  background: rgba(255, 180, 180, 0.8);
  padding: 10px;
  color: black;
  z-index: 100;
  font-size: 28px;
  cursor: pointer;

  .close {
    float: right;
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    margin-left: 15px;

    border-radius: 50%;
    border: solid 2px red;

    &:hover {
      &::before, &::after {
        background: white;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 16px;
      top: 50%;
      left: 2px;
      margin-top: -1px;
      background: red;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
