$font-path-bold: 'fonts/sh-new-bold';
@font-face {
  font-family: 'sh-new-bold';
  src: local('sh-new-bold'),
       font-url('#{$font-path-bold}.woff') format('woff'),
       font-url('#{$font-path-bold}.eot') format('embedded-opentype'),
       font-url('#{$font-path-bold}.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
$font-path-book: 'fonts/sh-new-regular';
@font-face {
  font-family: 'sh-new-regular';
  src: local('sh-new-regular'),
       font-url('#{$font-path-book}.eot') format('embedded-opentype'),
       font-url('#{$font-path-book}.woff') format('woff'),
       font-url('#{$font-path-book}.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "sh-new-regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.125rem;
}

.wrapper {
  max-width: 1206px;
  margin: auto;
}
