.search-form-container {
  min-height: 100%;
  @media (min-height: 900px) and (min-width: 410px) {

    .search-form {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
    }
  }
  background: radial-gradient(circle, rgba(255,255,255,1) 33%, rgba(238,255,255,1) 90%);

  .search-form {
    width: 90%;
    margin: auto;
    padding-top: 20px;

    @media (min-width: 720px) {
      max-width: 640px;
    }

    .title {
      font-family: "sh-new-bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 48px;
      font-weight: 600;

      background: linear-gradient(to right, #55c4e9 0%, #3998d8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }

    .subtitle {
      font-size: 24px;
      line-height: 1.5em;
      color: #404040;
      color: rgba(0, 0, 0, 0.75);
    }

    @media (min-width: 800px) and (min-height: 800px) {
      .title {
        font-size: 64px;
      }
      .subtitle {
        font-size: 32px;
      }
    }

    .input-group {
      text-align: left;
      margin-top: 20px;

      label {
        font-size: 16px;
      }

      .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
        width: 100%;
        max-width: none;
        box-shadow: none;
        .mapboxgl-ctrl-geocoder--input {
          border: 1px solid #000;
        }
      }

      .form-control {
        width: 100%;
        border: 1px solid #000;
        height: 50px;
        background: white;
        padding: 6px 12px;
        font-size: 18px;
      }
    }

    .advanced-options-button {
      margin-top: 25px;
      text-align: right;

      button {
        border: none;
        background: transparent;
        color: #0089cc;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .advanced-options {
      .advanced-options-title {
        margin-top: 25px;
      }
    }

    .get-results-button {
      button {
        background: #1e96d4;
        color: #fff;
        border-color: #1e96d4;
        padding: 6px 12px;
        margin-top: 40px;
        margin-bottom: 100px;
        cursor: pointer;
        border-radius: 0.25rem;
        border: 1px solid transparent;
        font-size: 15px;

        height: 50px;
        
        &:disabled {
          background: #aaa;
          cursor: inherit;
        }

        @media (max-height: 899px) {
          margin-bottom: 150px;
        }
      }
    }
  }
}
