.results {
  position: relative;
  margin-top: 50px;

  .results-title {
    position: absolute;
    left: -52px;
    top: 50px;
    width: 150px;
    text-align: right;
    padding-top: 7px;
    font-size: 15px;
    letter-spacing: 0.3em;
    border-top: solid 1px #0085bf;
    transform: rotate(-90deg);
  }

  .results-logo {
    opacity: 0.4;
    width: 56px;
    height: 42px;
    position: absolute;
    right: 10px;
    top: 0;
    background-image: url('logo.svg');
    background-repeat: no-repeat;
  }

  button.back-button {
    border: none;
    background: transparent;
    color: #0089cc;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    margin: 20px;
    margin-bottom: 50px;
    
    @media (max-height: 899px) {
      margin-bottom: 150px;
    }
  }

  

  .heading-usable {
    margin: 10px 65px;

    .usable-number {
      display: inline-block;
      vertical-align: middle;

      font-family: "sh-new-bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 28px;
      font-weight: 600;

      background: linear-gradient(to right, #55c4e9 0%, #3998d8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
  }

  .disclaimer {
    margin: 20px;
    padding: 5px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}
