.recommendations {
  text-align: left;
  margin: 10px;
  line-height: 2em;
  margin-bottom: 50px;

  .series {
    border-left: solid 2px #0085bf;
    color: #0085bf;
    font-family: "sh-new-bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 600;
    display: inline-block;
    padding-left: 10px;
  }

  .range {
    margin-top: 10px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;

    .range-title {
      padding: 5px;
      background-color: rgba(0,0,0,.03);
      border-bottom: 1px solid rgba(0,0,0,.125);
    }

    .recommendation-text {
      padding: 5px;
      font-size: 20px;
    }

    .mode {
      span {
        border-radius: 0.25rem;
        padding: 0.25em 0.4em;
      }

      .standard, .lr {
        color: #fff;
        background-color: #007bff;
      }
      .ld {
        color: #fff;
        background-color: #17a2b8;
      }
    }
  }
}
