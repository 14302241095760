.circles {
  display: inline-block;
  margin: auto;
  padding: 50px 0 30px 0;

  @media (max-width: 1024px) {
    padding: 10px 0;
  }

  .inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    border-radius: 50%;
    border: solid 2px;
    width: calc(100% - 5px);
    max-width: 160px;
    aspect-ratio: 1;

    .number {
      font-size: 24px;
      margin-top: 25%;

      @media (max-width: 1024px) {
        transform: translateY(4px);
      }
    }

    .status {
      font-size: 8px;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  .vacant {
    color: #75cf87;

    .inner-circle {
      border-color: #75cf87;
      background: rgb(240, 250, 242);
      background: radial-gradient(circle, rgba(255,255,255,1) 30%, rgba(224,245,229,1) 100%);
    }

    svg {
      fill: #75cf87;
    }
  }
  .usable {
    color: #f3d96c;

    .inner-circle {
      border-color: #f3d96c;
      background: rgb(254, 254, 253);
      background: radial-gradient(circle, rgba(255,255,255,1) 30%, rgba(253,248,226,1) 100%);
    }

    svg {
      fill: #cfb444;
    }
  }
  li {
    list-style-type: none;
    border-radius: 50%;
    border: solid 1px #0085bf;
    max-width: 200px;
    aspect-ratio: 1;

    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;

    &:after {
      width: 10px;
      height: 1px;
      background: #0085bf;
      content: "";
      position: absolute;
      right: -11px;
      top: 50%;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        content: none;
      }
    }

    @for $i from 1 through 10 {
      &:first-of-type:nth-last-of-type(#{$i}),
      &:first-of-type:nth-last-of-type(#{$i}) ~ li {
        width: #{calc(1206 / $i - (12 * ($i - 1) + 20) / $i)}px;

        @media (max-width: 1206px) {
          width: calc(#{calc(100 / $i)}vw - #{calc((12 * ($i - 1) + 30) / $i)}px);
        }
      }
    }
  }

  ol {
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}

.circles-placeholder {
  margin-top: 70px;
}
