body{
  overflow: hidden;
  position: fixed; left: 0; top: 0; right: 0; bottom: 0;
}
.App {
  text-align: center;

  .form-page {
    width: 100%;
    overflow: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    transition: transform 1s ease-in-out;
    -webkit-transition: transform 1s ease-in-out;
    -ms-transition: transform 1s ease-in-out;
    -moz-transition: transform 1s ease-in-out;
    position: relative;

    &.hide {
      transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
    }
  }

  .result-page {
    width: 100%;
    overflow: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    height: 100vh;
    transition: transform 1s ease-in-out;
    -webkit-transition: transform 1s ease-in-out;
    -ms-transition: transform 1s ease-in-out;
    -moz-transition: transform 1s ease-in-out;

    &.hide {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      -moz-transform: translateY(0);
    }
  }

  .spinner-modal {
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .spinner {
    width: 100px;
    height: 100px;
    border-top: 6px solid #0085bf;
    border-right: 6px solid #0085bf;
    border-radius: 100%;

    animation: spinner-spin-animation 0.6s infinite linear;

    position: absolute;
    top: 50%;
    left: 50%;

    margin-top: -50px;
    margin-left: -50px;

    border-width: 3px;
  }

  @keyframes spinner-spin-animation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}
